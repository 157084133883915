import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Tabs } from "react-bootstrap";
import LineChart from "../../components/LineChart/LineChart";
import BarChartHorizontal from "../../components/BarchartHorizontal/BarchartHorizontal";
import ForecastMultiplierModalView from "./ForecastMultiplierModalView";
import * as adaVisualActions from './actions/AdaVisualActions';
import * as AdaConst from './AdaVisualConstants';
import moment from "moment";
import "./AdaVisual.css";

/**
 * Sales View Component with Sales, Drivers and Forecast Adjustment section
 * @param {*} props 
 * @returns 
 */
const SalesView = (props) => {
    const [showForecastDialog, setShowForecastDialog] = useState(false);
    const [datesValue, setDatesValue] = useState(null);
    const [tabSelectionKey, setTabSelectionKey] = useState(AdaConst.ADA_DATA_WEEKLY);
    const [disableDaysList, setDisableDaysList] = useState([0, 2, 3, 4, 5, 6]);

    const { adaGraphData, adaAllocationReqBody, adaVisualActions, isAdaAllocationModeSelected,
        adaStandAloneReqBody, startDateStamp, endDateStamp, adaVisualData, accuracy } = props;
    var maxValue;
    var minValue;
    var year;

    if (adaGraphData) {
        // const values = adaGraphData?.actual_data.max()
        var filteredActualData = adaGraphData?.actual_data.filter(function (el) {
            return el != null;
          });
        const max =[Math.max.apply(null,adaGraphData?.actual_data),Math.max.apply(null,adaGraphData?.ia_data),Math.max.apply(null,adaGraphData?.adjusted_data),Math.max.apply(null,adaGraphData?.ly_sales)]
        const min=[Math.min.apply(null,filteredActualData),Math.min.apply(null,adaGraphData?.ia_data),Math.min.apply(null,adaGraphData?.adjusted_data), Math.min.apply(null,adaGraphData?.ly_sales)]
        maxValue = Math.max.apply(null,max)
        minValue = Math.min.apply(null,min)
        year = adaGraphData?.year && [...adaGraphData?.year]
        AdaConst.ADA_LINE_CHART_PARAMS.labels = [...adaGraphData.labels_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[0].data = [...adaGraphData.actual_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[1].data = [...adaGraphData.ia_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[2].data = [...adaGraphData.adjusted_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[3].data = [...adaGraphData.ly_sales];
        AdaConst.ADA_BAR_CHART_PARAMS.labels = [...adaGraphData.labels_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[0].data = [...adaGraphData.actual_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[1].data = [...adaGraphData.ia_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[2].data = [...adaGraphData.adjusted_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[3].data = [...adaGraphData.ly_sales];
    }

    function handleDateChange(e) {
        setDatesValue(e.value);
        if (!e.value[1]) {
            let tempList = [1, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else if (e.value[1]) {
            let tempList = [0, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else {
            //No code here
        }
    }

    useEffect(() => {
        if (datesValue && datesValue[0] && datesValue[1]) {
            adaVisualActions.setAdaStartEndDate({
                start_date: moment(datesValue[0]).format("YYYYWW"),
                end_date: moment(datesValue[1]).format("YYYYWW"),
                start_stamp: datesValue[0],
                end_stamp: datesValue[1],
            });

            let req = {};
            const currentDateKey = moment().format("YYYY-MM-DD")
            if (isAdaAllocationModeSelected) {
                req = {
                    adaReq: {
                        start_fw: moment(datesValue[0]).format("YYYYWW"),
                        end_fw: moment(datesValue[1]).format("YYYYWW"),
                        current_fw: adaAllocationReqBody.adaReq.current_fw,
                        current_date: currentDateKey,
                        articles: {
                            ...adaAllocationReqBody.adaReq.articles,
                        },
                        l1_name: adaAllocationReqBody?.level1,
                        l2_name: adaAllocationReqBody?.level2,
                        l3_name: adaAllocationReqBody?.level3,
                        l4_name: adaAllocationReqBody?.level4,
                        l5_name: adaAllocationReqBody?.level5,
                        l6_name:adaAllocationReqBody?.level6,
                        l7_name:adaAllocationReqBody?.level7,
                        assortment_indicator: adaAllocationReqBody?.assortment_indicator,
                        store_code: adaAllocationReqBody?.store_code,
                    }
                };
                adaVisualActions.getAdaAllocationData(req);
            } else {
                req = {
                    start_fw: moment(datesValue[0]).format("YYYYWW"),
                    end_fw: moment(datesValue[1]).format("YYYYWW"),
                    current_fw: adaStandAloneReqBody?.current_fw,
                    current_date: currentDateKey,
                    l1_name: adaStandAloneReqBody && [...adaStandAloneReqBody?.l1_name],
                    l2_name: adaStandAloneReqBody && [...adaStandAloneReqBody?.l2_name],
                    l3_name: adaStandAloneReqBody && adaStandAloneReqBody.l3_name && [...adaStandAloneReqBody?.l3_name],
                    l4_name: adaStandAloneReqBody && adaStandAloneReqBody.l4_name && [...adaStandAloneReqBody?.l4_name],
                    l5_name: adaStandAloneReqBody && adaStandAloneReqBody.l5_name && [...adaStandAloneReqBody?.l5_name],
                    l6_name: adaStandAloneReqBody && adaStandAloneReqBody.l6_name && [...adaStandAloneReqBody?.l6_name],
                    l7_name: adaStandAloneReqBody && adaStandAloneReqBody.l7_name && [...adaStandAloneReqBody?.l7_name],
                    article: adaStandAloneReqBody && adaStandAloneReqBody.articles && [...adaStandAloneReqBody?.articles],
                    assortment_indicator: adaStandAloneReqBody && adaStandAloneReqBody.assortment_indicator && [...adaStandAloneReqBody?.assortment_indicator],
                    store_code: adaStandAloneReqBody && adaStandAloneReqBody.store_code && [...adaStandAloneReqBody?.store_code],
                };
                adaVisualActions.getAdaStandAloneData(req);
            }
        } else {
            //No code here
        }
    }, [datesValue]);

    const currentDate = moment().format("WW");
    let currentWeek = (26 + parseInt(currentDate)) % 52
    currentWeek = currentWeek === 0 ? 52 : currentWeek;

    const WeekNumber = AdaConst?.ADA_LINE_CHART_PARAMS?.labels.map(function (value) {
        var v = value.slice(1, 3)
        return parseInt(v)
    });
    //for PastWeek
    const { current_fw } = adaStandAloneReqBody;
    var PastWeekData = []
    var FutureWeekData = []
    var FisYear = []

    adaVisualData.forEach((item) => {
        if (parseInt(item.fy_fw) < parseInt(current_fw)) {
            PastWeekData.push(item.week)
        }
        if (parseInt(item.fy_fw) > parseInt(current_fw)) {
            FutureWeekData.push(item.week)
        }
        if (parseInt(item.fy_fw) == parseInt(current_fw)) {
            FisYear.push(item.year)
        }
    })

    const PastWeekDataLength = PastWeekData.length
    //for futureWeek
    // const FutureWeekData = WeekNumber.filter(function(value) {
    //     if (value > currentWeek )
    //     {
    //         return value
    //     }
    //  });
    const FutureWeekDataLength = FutureWeekData.length

    //  const FiscalYear = adaGraphData && [adaGraphData?.year];




    return (
        <>
            <div id="SALES_DATA_CONTAINER" className="sales_data_container">
                <div className="ada__tabs">
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: "12%" }}>
                            {PastWeekData.length !== 0 &&
                                <span style={{ fontSize: "11px", fontWeight: "700" }}>Past Weeks: <span style={{ color: "darkgoldenrod" }}>{`W${PastWeekData[0]} - W${PastWeekData[PastWeekDataLength - 1]}`}</span></span>}
                        </div>
                        <div style={{ marginLeft: "5px", width: "10%" }}>
                            {PastWeekData.length !== 0 &&
                                <span style={{ fontSize: "11px", fontWeight: "700" }}>Current Week: <span style={{ color: "blue" }}>{`W${currentWeek}`}</span></span>}
                        </div>
                        <div style={{ marginLeft: "7px", width: "13%" }}>
                            {FutureWeekData.length !== 0 &&
                                <span style={{ fontSize: "11px", fontWeight: "700" }}>Future Weeks: <span style={{ color: "darkmagenta" }}>{`W${FutureWeekData[0]} - W${FutureWeekData[FutureWeekDataLength - 1]}`}</span></span>}
                        </div>
                        <div style={{ marginLeft: "7px", width: "13%" }}>
                            {year?.length !== 0 &&
                                <span style={{ fontSize: "11px", fontWeight: "700" }}>Fiscal Year: <span style={{ color: "blue" }}>{`${FisYear?.[0]}`}</span></span>}
                        </div>
                        {/* <Calendar
                    id="DATE_RANGE"
                    value={datesValue}
                    onChange={(e) => handleDateChange(e)}
                    selectionMode="range"
                    style={{ height: "35px" }}
                    disabledDays={disableDaysList}
                    readOnlyInput
                    showIcon
                /> */}
                        {/* <Tabs activeKey={tabSelectionKey} className="calendar-tab" onSelect={(key) => {
                    adaVisualActions.setAdaDataTimeConstraint(key);
                    setTabSelectionKey(key);
                }}>
                    <Tab eventKey={AdaConst.ADA_DATA_WEEKLY} title={AdaConst.GRAPH_WEEK} />
                    <Tab eventKey={AdaConst.ADA_DATA_MONTHLY} title={AdaConst.GRAPH_MONTH} disabled={true} />
                    <Tab eventKey={AdaConst.ADA_DATA_QUARTERLY} title={AdaConst.GRAPH_QUARTER} disabled={true} />
                </Tabs>  */}
                        {accuracy &&
                            <div className="sales_text" style={{ display: 'flex', marginLeft: "28%" }}>
                                <div className="circleIcon" style={{ display: 'flex', width: "45%" }}>
                                    <div className="circle yellow"></div>
                                    <span className="percent">{accuracy.ia_forecast} %</span>
                                    <span className="text-IA">IA Forecast Accuracy</span>

                                </div>
                                <div className="circleIcon" style={{ display: 'flex' }}>
                                    <div className="circle blue"></div>
                                    <span className="percent">{accuracy.adjusted_forecast} %</span>
                                    <span className="text-Ad">Adjusted Forecast Accuracy</span>

                                </div>
                            </div>}
                    </div>
                    <hr />
                    <div>
                        <div style={{ margin: "5% 1% 0% 0%" }}>
                            {adaVisualData.length === 0
                                ?
                                <div className="empty">{AdaConst.EMPTY_FORECAST_MSG}
                                    <p>{AdaConst.EMPTY_DATA_HELPER_MSG}</p>
                                </div>
                                :
                                <Tabs defaultActiveKey="lineChart" className="graph-types-tab">
                                    <Tab eventKey="lineChart" title="Line">
                                        <h2 className="line-chart__heading">{AdaConst.SALES_DISPLAY_HEADER}</h2>
                                        <LineChart data={AdaConst.ADA_LINE_CHART_PARAMS} height="6" width="20" maxValue={maxValue} minValue={minValue}
                                            showForecastDialog={setShowForecastDialog} />
                                    </Tab>
                                    <Tab eventKey="barChart" title="Bar">
                                        <h2 className="line-chart__heading">{AdaConst.SALES_DISPLAY_HEADER}</h2>
                                        {AdaConst.ADA_BAR_CHART_PARAMS.length !== 0 ? <BarChartHorizontal chartType="bar" data={AdaConst.ADA_BAR_CHART_PARAMS} height="6" width="20" text="" /> : null}

                                    </Tab>
                                    {/* <Tab eventKey="pieChart" title="Pie">
                            </Tab> */}
                                </Tabs>
                            }
                        </div>

                    </div>
                </div>

                {showForecastDialog ?
                    (<ForecastMultiplierModalView
                        showAlert={true} setShowForecastDialog={setShowForecastDialog} />)
                    : null}
            </div>
            {adaVisualData.length !== 0 &&
                <div className="forecast-button" hidden={!adaGraphData || !AdaConst.ADA_BAR_CHART_PARAMS}>
                    <button className="btn btn-primary" style={{ width: "15vw" }}
                        // disabled={!startDateStamp && !endDateStamp}
                        onClick={() => setShowForecastDialog(true)}>
                        {AdaConst.FORECAST_MULTIPLIER}
                    </button>
                </div>
            }
        </>
    );
}

const mapStateToProps = ({ adaVisual }) => ({
    adaVisualData: adaVisual.adaVisualData,
    adaGraphData: adaVisual.adaGraphData,
    adaAllocationReqBody: adaVisual.adaAllocationReqBody,
    isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    accuracy: adaVisual.accuracy,
    startDateStamp: adaVisual.startDateStamp,
    endDateStamp: adaVisual.endDateStamp,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesView);